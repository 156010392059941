const CLASS_FORM = 'm-newsletter__form'
const CLASS_SUCCESS = 'm-newsletter--success'
const CLASS_ERROR = 'm-newsletter--error'
const NAME_INPUT_EMAIL = 'newsletter-email'
const XHR_ENDPOINT = '/nex/modules/newsletter_signup/v1?isXHR=true&m_mode=json'
const COOKIE_NAME = 'isNewsLetterEnable'
const CLASS_SIGNED_UP = 'm-newsletter--signed-up'
const CLASS_CONTAINER = 'm-newsletter__container'

export default (() => {
  class NewsLetterController {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]

      if (!this.elems.length) {
        return
      }
      this.utils = window.NEXUtils || {}

      this.elems.forEach(elem => {
        this.init(elem)
      })
    }

    init(elem) {
      const isSignedUp = elem.classList.contains(CLASS_SIGNED_UP)
      if (isSignedUp) {
        this.updateContainerClass(elem)
      } else {
        const signupForm = elem.querySelector(`.${CLASS_FORM}`)
        signupForm.addEventListener('submit', e => this.submitHandler(e, elem))
      }
    }

    updateContainerClass(elem) {
      const container = elem.closest(`.${CLASS_CONTAINER}`)
      container?.classList.add(CLASS_SIGNED_UP)
    }

    submitHandler(e, elem) {
      e.preventDefault()
      const form = e.currentTarget

      const requestData = {
        method: form.method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: form[NAME_INPUT_EMAIL]?.value
        })
      }

      fetch(XHR_ENDPOINT, requestData)
        .then(response => response.json())
        .then(responseData => {
          const {
            data: {
              response: { success }
            }
          } = responseData

          if (success) {
            this.setResponseUI(elem, true)
            this.setNewsletterCookie()
          } else {
            this.setResponseUI(elem, false)
          }
        })
        .catch(err => {
          this.setResponseUI(elem, false)
        })
    }

    setResponseUI(elem, isSuccess) {
      if (isSuccess) {
        elem.classList.remove(CLASS_ERROR)
        elem.classList.add(CLASS_SUCCESS)
      } else {
        elem.classList.remove(CLASS_SUCCESS)
        elem.classList.add(CLASS_ERROR)
      }
    }

    setNewsletterCookie() {
      const currentTime = new Date().getTime()
      this.utils.setCookie(COOKIE_NAME, currentTime, 3)
    }
  }

  return NewsLetterController
})()
