const d = new Date()
const CURRENT_TIME = d.getTime()
const AOL_NEWSLETTER = 'aol-newsletter'
const NEWSLETTER_FORM = 'sign-up-form'
const NEWSLETTER_INPUT = 'signup-email'
const NEWSLETTER_MESSAGE = 'newsletter-message'
const NEWSLETTER_COOKIE_NAME = 'isNewsLetterEnable'
const NEWSLETTER_COOKIE = window.NEXUtils.getCookie(NEWSLETTER_COOKIE_NAME)
const NEWSLETTER_ALERT = 'newsletter-alert'
const NEWSLETTER_BANNER = 'newsletter-banner'

const _postData = async (url = '', data = {}) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

  return response
}

const _createNewsletterMsg = (content = {}) => {
  const message = document.createElement('div')
  message.setAttribute('id', NEWSLETTER_MESSAGE)
  message.setAttribute('class', NEWSLETTER_MESSAGE)

  const newSpan = document.createElement('span')
  newSpan.setAttribute('class', content.isSuccess ? 'success' : 'error')
  newSpan.textContent = content.isSuccess ? 'Success!' : 'Error.'

  const newText = document.createTextNode(` ${content.text}` || '')

  message.appendChild(newSpan)
  message.appendChild(newText)

  return message
}

export default (() => {
  class NewsletterSignup {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]

      if (!this.elems.length) {
        return
      }

      this.newsLetter = document.getElementById(AOL_NEWSLETTER)
      this.newsLetterAlert = document.getElementById(NEWSLETTER_ALERT)
      this.newsLetterBanner = document.getElementById(NEWSLETTER_BANNER)
      this.newsLetterForm = document.getElementById(NEWSLETTER_FORM)

      if (!this.newsLetter || !this.newsLetterAlert) {
        return
      }

      this.init()
    }

    init() {
      this.CheckNewsLetterCookie()
      this.signUp()
    }

    signUp() {
      const signupForm = document.getElementById(NEWSLETTER_FORM)
      signupForm.addEventListener('submit', e => this.signUpHandler(e))
    }

    signUpHandler(evt) {
      evt.preventDefault()
      const signupEmail = document.getElementById(NEWSLETTER_INPUT).value

      _postData('/nex/modules/newsletter_signup/v1?domain=wwwaolcom&isXHR=true&m_mode=json', {
        email: signupEmail
      })
        .then(response => response.json())
        .then(responseData => {
          const {
            data: {
              response: { success }
            }
          } = responseData

          if (success) {
            this.responseMessage(true)
            this.SetNewsletterCookie()
            document.getElementById(NEWSLETTER_FORM).remove()
          } else {
            this.responseMessage(false)
          }
        })
    }

    responseMessage(type) {
      if (document.getElementById(NEWSLETTER_MESSAGE)) {
        const myobj = document.getElementById(NEWSLETTER_MESSAGE)
        myobj.remove()
      }

      const isType = type === true
      const messageObj = {
        text: isType
          ? 'Thank you for signing up to the AOL newsletter'
          : 'Registration failed, please try again.',
        isSuccess: isType
      }

      this.newsLetterAlert.appendChild(_createNewsletterMsg(messageObj))
    }

    SetNewsletterCookie() {
      window.NEXUtils.setCookie(NEWSLETTER_COOKIE_NAME, CURRENT_TIME, 3)
    }

    CheckNewsLetterCookie() {
      if (NEWSLETTER_COOKIE && !this.newsLetterBanner) {
        this.newsLetter.style.display = 'none'
      } else if (NEWSLETTER_COOKIE && this.newsLetterBanner) {
        this.responseMessage(true)
        if (this.newsLetterForm) {
          this.newsLetterForm.parentNode.removeChild(this.newsLetterForm)
        }
      } else {
        this.newsLetter.style.display = 'block'
      }
    }
  }

  return NewsletterSignup
})()
