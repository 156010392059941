import LegacyController from './controller'
import Controller from './newsletter_controller'

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const logDecorator = require('../../../../lib/decorators/log')
  logDecorator(Controller)
}

class NewsLetter {
  constructor() {
    this.legacyController = new LegacyController({
      selector: '.m-newsletter-signup'
    })
    this.controller = new Controller({
      selector: '.m-newsletter'
    })
  }
}

export default new NewsLetter()
